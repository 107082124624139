<template>
  <div class="product-list">
    <transition-group tag="ul" name="list">
      <li class="product-li" v-for="item in list.data" :key="item">
        <router-link :to="{ name: name, params: { id: item.id, classId: item.classId ? item.classId : item.nclassId} }">
          <div class="product-img">
            <img v-if="typeFlag == 'collection'" :src="item.productPicLink" />
            <img v-else :src="item.pic" />
          </div>
          <div class="product-info-wrap">
            <div class="product-title">
              <span v-html="item.title"></span>
              <!--addShopCar(id: string, tid: number, qty: number, lotid: number, tcid: number, stock: number-->
<!--              <img-->
<!--                  src="@/assets/img/common/icon49.png"-->
<!--                  @click.prevent="addShopCar(item.id,item.anclassId, 1,-->
<!--                item.anclassId==1?0-->
<!--                  :((item.anclassId==2&&item.nclassId==8&&item.productFormat && item.productFormat.includes('冻存管'))?19-->
<!--                    :(item.nclassId==8 || item.nclassId == 25 ?3:0)),-->
<!--                item.anclassId==1?7:(item.nclassId==8 ?10:0), item.stock,1)"-->
<!--              />-->
              <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/7de683ec9239f41001c7a4fd0e11c4e0.png" alt="">
            </div>

            <div class="product-card-info">
              <span v-html="item.bnccid"></span>
              <span class="wy-brand">BNCC</span>
            </div>
            <div class="product-card-brand">
              <span v-html="item.speciesGenus" style="display: inline;"></span>
            </div>
            <!--此处需要加入 priceopen的判断-->
            <div v-if="biaowuPriceOpen && biaowuPriceOpen == '1'" class="product-card-brand">
<!--              <span class="typeFlag=='search'" style="color:#20c2d6;">现货</span>-->
              <span class="price" v-if="userInfo  && item.anclassId==1 && userInfo.isDealer==2">
                ￥{{ Math.ceil(Number(item.price * (userInfo.cellDiscount ? userInfo.cellDiscount : 1)).toFixed(2)) || '' }}
              </span>
              <span class="price" v-else-if="userInfo  && item.anclassId!=1 && userInfo.isDealer==2">
                ￥{{ Math.ceil(Number(item.price * (userInfo.bnccDiscount ? userInfo.bnccDiscount : 1)).toFixed(2)) || '' }}
              </span>
              <span class="price" v-else-if="userInfo.isDealer==2  && item.price">￥{{ Number(item.price) || '' }}</span>
              <span class="price" v-else-if="item.retailPrice">￥{{ Number(item.retailPrice) || '' }}</span>
              <span class="price" v-else>￥{{ Number(item.price) || '' }}</span>
            </div>


          </div>
        </router-link>
      </li>
    </transition-group>
    <transition-group tag="ul" v-if="list.loading" name="list">
      <li class="product-li skeleton" v-for="item in 10" :key="item">
        <div class="product-img skeleton-bg"></div>
        <div class="product-info-wrap">
          <div class="product-title skeleton-bg"></div>
          <div class="product-card-info skeleton-bg"></div>
          <div class="product-card-brand skeleton-bg"></div>
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, isReactive, reactive, inject, watch } from "vue";
  import api from "@/api/axios";
  import Toast from "@/components/UI/Toast/";
  import useShopCar from "@/hooks/useShopCar";
  import { useRouter } from "vue-router";

  export default defineComponent({
    props: {
      list: Object,
      name: String,
      typeFlag: {
        type: String,
        default: ""
      }
    },
    setup(props) {
      const userInfo = inject("userInfo") as any;
      const biaowuPriceOpen = ref()
      const router = useRouter();

      watch(
              () => {
                return props.list
              },
              (NewsVue) => {
                biaowuPriceOpen.value = (NewsVue as any).totalResult && (NewsVue as any).totalResult[0] && (NewsVue as any).totalResult[0].str
              }
      )

      //  add shop car
      const { addShopCar } = useShopCar();
      return {
        addShopCar,
        userInfo,
        biaowuPriceOpen
      };
    },
  });
</script>

<style lang="scss" scoped>
  .product-ul {
    overflow: hidden;
  }
  // list data

  .product-li {
    width: 100%;
    height: 100%;
    padding: 15px 10.012px;
    overflow: hidden;
    box-sizing: border-box;
    border-bottom: 1px solid #f4f4f4;
    .product-img {
      width: 73px;
      height: 73px;
      float: left;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .product-info-wrap {
      height: 73px;
      margin-left: 2.15rem;
    }
    .product-title {
      // font-size: 16px;
      font-size: 14.8px;
      // color: #333;
      color: #111;
      line-height: 17.5px;
      // height: 32px;
      padding-right: 42.375px;
      box-sizing: border-box;
      // margin-bottom: 8px;
      margin-bottom: 5px;
      position: relative;
      overflow: hidden;
      span{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      img {
        position: absolute;
        // right: 23px;
        right: 12px;
        top: 0;
        width: 17px;
        height: 16px;
        z-index: 1;
      }
    }
    .product-spot {
      color: #20c2d6;
    }
    .product-card-info {
      // font-size: 14px;
      font-size: 12px;
      color: #999;
      line-height: 16px;
      // margin-bottom: 12px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;

      // padding-right: 42.375px;
      width: 92%;
      // display: flex;
      // justify-content: space-between;
      .wy-brand {
        background: #d64b4b;
        color: #fff;
        padding: 0 5px;
        margin-left: 10px;
        font-size: 10px;
      }
      .price {
        color: #d64b4b;
      }
    }
    .product-card-brand {
      color: #999;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 5px;
      // font-size: 14px;
      font-size: 12px;
      color: #999;
      // line-height: 16px;
      line-height: 19px;
      .price {
        color: #d64b4b;
        //margin-left: 10px;
      }
    }
  }

  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    to {
      background-position: 0 50%;
    }
  }

  .skeleton {
    .product-card-info {
      height: 16px;
      width: 70% !important;
    }
    .product-card-brand {
      height: 14px;
      width: 50% !important;
    }
    .skeleton-bg {
      background: linear-gradient(
                      90deg,
                      hsla(0, 0%, 74.5%, 0.2) 25%,
                      hsla(0, 0%, 50.6%, 0.24) 37%,
                      hsla(0, 0%, 74.5%, 0.2) 63%
      );
      background-size: 400% 100%;
      animation: skeleton-loading 1.4s ease infinite;
    }
  }

  .save-area {
    width: 100%;
    height: 45px;
    position: relative;
    top: 50px;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 58%;
  }

  .save-area a {
    display: inline-block;
    width: 35%;
    height: 45px;
    background-color: #999;
    // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
    border-radius: 3.75px;
    line-height: 45px;
    text-align: center;
    color: #ffffff !important;
    font-size: 15px;
    border-radius: 25px;
  }

  .save-area .save {
    width: 35%;
    height: 45px;
    background-color: #E00024;
    // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
    border-radius: 3.75px;
    line-height: 45px;
    text-align: center;
    color: #ffffff;
    border: none;
    outline: none;
    font-size: 15px;
    border-radius: 25px;
  }
</style>
